<template>
    <LayoutNext>
        <div class="backdrop" v-if="loadingSpinner">
            <div class="image-container d-flex justify-content-center align-items-center">
                <div class="loader"></div>
                <img src="@/assets/cs-loader.png" alt="Loading image">
            </div>
        </div>
        <template #page-title>
            Add Creator for Notification
        </template>
        <nav aria-label="breadcrumb" style="font-size: 13px;">
            <MDBBreadcrumb>
                <MDBBreadcrumbItem><router-link to="/daily-sync">Daily Sync</router-link>
                </MDBBreadcrumbItem>
                <MDBBreadcrumbItem active style="cursor: default;">
                    Add Creator for Notification
                </MDBBreadcrumbItem>
            </MDBBreadcrumb>
        </nav>
        <MDBCard class="w-75 m-auto shadow">
            <MDBCardHeader class="px-4 py-3">
                <h5 class="my-auto fw-bold header">Content Creator Information</h5>
            </MDBCardHeader>
            <MDBCardBody class="d-flex flex-column" style="gap: 15px">
                <div>
                    <label for="creatorName">Creator Name</label>
                    <MDBInput class="mt-1" type="text" placeholder="Enter Creator Name" v-model="form.creatorName"
                        id="creatorName" />
                </div>
                <div>
                    <label for="videoPageLink">Videos Page Link</label>
                    <MDBInput class="mt-1" type="text" placeholder="Enter Video Page Link" v-model="form.videosPageLink"
                        id="videoPageLink" />
                </div>
                <div>
                    <div>
                        <label for="frequencyType">Scan FrequencyType</label>
                        <select v-model="form.frequencyType" class="did-floating-select mt-1" id="frequencyType">
                            <option v-for="option in frequencyType" :key="option.value" :value="option.value">{{
                                option.text
                            }}
                            </option>
                        </select>
                    </div>
                </div>
                <div v-if="form.frequencyType == '2'">
                    <label for="week">Day of the Week</label>
                    <select v-model="form.frequency" class="did-floating-select mt-1" id="week">
                        <option v-for="option in dayOfTheWeek" :key="option.value" :value="option.value">{{ option.text
                            }}
                        </option>
                    </select>
                </div>
                <div v-if="form.frequencyType == '3'" class="mt-1">
                    <label for="monthly">Day of the Week</label>
                    <select v-model="form.frequency" class="did-floating-select mt-1" id="monthly">
                        <option v-for="option in dayOfTheMonth" :key="option.value" :value="option.value">{{ option.text
                            }}
                        </option>
                    </select>
                </div>
            </MDBCardBody>
            <MDBCardFooter class="py-3 d-flex justify-content-end">
                <MDBBtn class="fw-bold" type="submit" @click="submit" color="primary" size="sm"
                    :disabled="isAddBtnDisabled">Add
                    Creator
                </MDBBtn>
            </MDBCardFooter>
        </MDBCard>
    </LayoutNext>
</template>

<script setup>
import LayoutNext from "@/views/v3/LayoutNext.vue";
import { computed, reactive, ref } from "vue";
import { useRouter } from "vue-router";
import { MDBCard, MDBCardHeader, MDBCardBody, MDBCardFooter, MDBBtn, MDBInput, MDBBreadcrumb, MDBBreadcrumbItem } from "mdb-vue-ui-kit";
import { PostCreatorForNotification } from "@/services/Notifications/PostCreatorForNotification";
import { useToastStore } from "@/store/toastStore";
import { useTitle } from "@vueuse/core";

useTitle("Add Creator for Notification | CreatorShield");

const toastStore = useToastStore();
const router = useRouter();

const form = reactive({
    creatorName: "",
    videosPageLink: "",
    frequencyType: "1",
    frequency: "",
});

const dayOfTheWeek = [
    { text: 'Monday', value: '1' },
    { text: 'Tuesday', value: '2' },
    { text: 'Wednesday', value: '3' },
    { text: 'Thursday', value: '4' },
    { text: 'Friday', value: '5' },
];

const dayOfTheMonth = [
    { text: '1st', value: '1' },
    { text: '15th', value: '15' },
    { text: 'Last', value: '30' },
];

const frequencyType = [
    { text: 'Daily', value: '1' },
    { text: 'Weekly', value: '2' },
    { text: 'Monthly', value: '3' },
];

const loadingSpinner = ref(false);

const isAddBtnDisabled = computed(() => form.creatorName === "" || form.videosPageLink === "");

const submit = async () => {
    const formData = {
        creatorName: form.creatorName,
        videosPageLink: form.videosPageLink,
        frequencyType: form.frequencyType,
        frequency: form.frequency,
    };

    loadingSpinner.value = true;
    const toastSuccess = {
        ...toastStore.toastStatus.success,
        message: "Successfully added!",
    };

    await PostCreatorForNotification(formData)
        .then(() => {
            toastStore.toastObject = {
                ...toastStore.toastObject,
                ...toastSuccess,
            };
        })
        .catch((response) => {
            if (response.status !== 200 && response.status !== 201) {
                toastStore.toastObject = {
                    ...toastStore.toastObject,
                    ...toastStore.toastStatus.fail,
                };
            }
        })
        .finally(() => {
            loadingSpinner.value = false;
            router.push({ name: "DailyVideoUpdatesManagement" });
        });
};
</script>

<style scoped>
.btn-primary {
    background-color: var(--primary);
}

.btn-outline-primary {
    border-color: var(--accent);
    color: var(--accent);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 10px 0 rgba(0, 0, 0, 0.1);
}

/* customized select with floating label */
.did-floating-label-content {
    position: relative;
}

.did-floating-label {
    color: #1e4c82;
    font-size: 12.5px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 6px;
    top: 11px;
    padding: 0 5px;
    background: #fff;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
}

.did-floating-input,
.did-floating-select {
    font-size: 16px;
    display: block;
    width: 100%;
    height: 35px;
    padding: 0 12.5px;
    padding-right: 13px;
    background: #fff;
    color: #6c757d;
    border: 1px solid #adb5bd;
    border-radius: 4px;
    box-sizing: border-box;

    &:focus {
        outline: 1.5px solid #0d6efd;

        ~.did-floating-label {
            color: #0d6efd;
            top: -8px;
            font-size: 12.5px;
        }
    }
}

select.did-floating-select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

select.did-floating-select::-ms-expand {
    display: none;
}

.did-floating-input:not(:placeholder-shown)~.did-floating-label {
    top: -8px;
    font-size: 12.5px;
}

.did-floating-select:not([value=""]):valid~.did-floating-label {
    top: -8px;
    font-size: 12.5px;
}

.did-floating-select[value=""]:focus~.did-floating-label {
    top: 11px;
    font-size: 13px;
}

.did-floating-select:not([multiple]):not([size]) {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='6' viewBox='0 0 8 6'%3E%3Cpath id='Path_1' data-name='Path 1' d='M371,294l4,6,4-6Z' transform='translate(-371 -294)' fill='%23003d71'/%3E%3C/svg%3E%0A");
    background-position: right 15px top 50%;
    background-repeat: no-repeat;
}

@media screen and (max-width: 767px) {

    .did-floating-input,
    .did-floating-select {
        width: 100%;
    }
}

.image-container {
    position: relative;
    display: inline-block;
}

.image-container .loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 50px;
    height: 50px;
    border: 3px solid rgba(0, 0, 0, 0.1);
    border-top-color: rgb(153, 153, 153);
    border-radius: 50%;
    animation: BorderSpin 1s linear infinite;
}

.image-container img {
    display: block;
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

@keyframes BorderSpin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
